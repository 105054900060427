import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { pathToViewType } from 'utils/testable/pathToViewType'

export const executeTrackingCall = (page: string): void => {
    // @ts-expect-error: __envConfig came from backend
    if (typeof _etracker === 'object') {
        const viewType = pathToViewType(page)
        const pageParts = page.split('/')
        let pageName = pageParts[1]
        if (viewType === ViewType.AVAILABILITY_CHECK) {
            pageName = 'Verfügbarkeitsprüfung'
        } else if (viewType === ViewType.PRODUCT_SELECTION || viewType === ViewType.PRODUCT_CROSS_SELLING) {
            pageName += '/' + pageParts[2]
        } else if (viewType === ViewType.OPTIONS_CONFIGURATION) {
            pageName += '/' + pageParts[4] + '/' + pageParts[2]
        } else if (viewType === ViewType.PRODUCT_TYPE_SELECTION) {
            pageName += '/' + pageParts[3]
        } else if (viewType === ViewType.VZF_VIEW) {
            pageName = 'Vertrags­zusammen­fassung'
        }
        // @ts-expect-error: __envConfig came from backend
        et_eC_Wrapper({ et_et: '4w3GQb', et_pagename: pageName })
    }
}

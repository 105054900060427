const colors = {
    black: '#000000',
    color1: '#222222',
    color2: '#8FABF2',
    color3: '#CFA22E',
    darkGray: '#929292',
    basketGray: '#909090',
    darkerGray: '#737373',
    green: '#00A666',
    lightGreen: '#BBDDC3',
    header: '#F8F8F8',
    lightGray: '#F3F0F0',
    midGray: '#C4C4C4',
    warning: '#EB5757',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    white: '#FFFFFF',
    basketInfoIcon: 'rgba(34, 34, 34, 0.54)',
    buttonPrimaryColor: '#00A666',
    optionItemBorderColor: '#00A666',
    optionItemBackgroundColor: '#FFFFFF',
    optionItemFontColor: '#00A666',
    productCategoryCardBorderColor: '#00A666',
    productCategoryCardHeadBGColor: '#00A666',
    productCategoryCardBGColor: '#FFFFFF',
    productCategoryCardFontColor: '#00A666',
    configurationItemBackgroundColorHead: '#00A666',
    configurationItemBackgroundColorCard: '#FFFFFF',
    configurationItemTextColor: '#00A666',
    configurationItemBorderColor: '#00A666',
    orderVerificationEdit: '#929292',
}

export default colors
